import React from 'react'
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';

const Header = () => {

	return (

		<>
		<Navbar expand="lg" className="bg-white fixed-top"   style={{
			boxShadow:
			  "0 1px 6px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.15)",
		  }}>
		<Container>
		<Image className='mx-3 img-fluid'  src="\Images\WSSL FINAL LOGO 3_L2.jpg" style={{ width:'16rem',height:"auto" }} />
		  <Navbar.Toggle aria-controls="basic-navbar-nav" />
		  <Navbar.Collapse id="basic-navbar-nav">
			
		  <Nav
		  className="ms-auto my-2 my-lg-0">
		  <Nav.Link as={NavLink} activeClassName="active" to="/" className='fs-5 fw-medium' style={{}}>Home</Nav.Link>
		  <Nav.Link as={NavLink} activeClassName="active" to="/about" className='fs-5 fw-medium'>About</Nav.Link>
		  <Nav.Link as={NavLink} activeClassName="active" to="/services" className='fs-5 fw-medium'>Services</Nav.Link>
		  <Nav.Link as={NavLink} activeClassName="active" to="/contact" className='fs-5 fw-medium'>Contact</Nav.Link>
		  
		</Nav>
	


		  </Navbar.Collapse>
		  </Container>
		  </Navbar>
		  <br/><br/><br/>
		</>
  
	  
  )
}

export default Header;
