import React from 'react'

const ErrorPage = () => {
  return (
	<div>
	  Error
	</div>
  )
}

export default ErrorPage
