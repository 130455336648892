import React from 'react'
import { Container, Image } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Whatsappicon from './components/Whatsappicon';
const About = () => {
	document.title=" About Us - World Standards Scientific LLP (WSSL)"
  return (
	<div>
	<Whatsappicon />

	<br/><br/>

    <div className='text-center' style={{backgroundImage:`url("./Images/background/bg-about.jpg")`, height:"55vh",width:"100%",backgroundSize:"cover"}}>
	<h1 className=' fw-bold' style={{color:"#ffff",paddingTop:"86px"}}>ABOUT COMPANY</h1>
	<h6 style={{color:"#ffff"}} className="fw-bold">HOME / <span style={{color:" #61dafb"}}>ABOUT</span></h6>
	
	</div>

	<br/>
	<Container>
	
	<Row className="justify-content-center">
	  <Col xs={12} md={8} lg={6}>
		<div style={{ width: "100%" }} className="m-3">
		  <p>
			<p>
			  
			  <Card.Subtitle className="mb-2"></Card.Subtitle>
			  <p>
			  World Standards Scientific LLP (WSSL) is a leading consulting firm renowned for its reliable services. Specializing in a wide range of certification and compliance solutions, WSSL offers:

			<p><i className='fa fa-check-circle'/>  Product Testing Laboratory & ISI / BIS Certification</p>
			<p> <i className='fa fa-check-circle'/>  Food Testing Laboratory & Food License Certification </p>
			<p> <i className='fa fa-check-circle'/>  Training on Regulatory Compliances
			 </p><p><i className='fa fa-check-circle'/> Indian Standard Assessment such as ISI Certification Services
			</p><p><i className='fa fa-check-circle'/>  Foreign Manufacturer Certification</p>
			<p><i className='fa fa-check-circle'/>  NABL Accreditation Consultancy </p>
			 <p><i className='fa fa-check-circle'/> Indian BIS And CRS Scheme for Electronics & IT Goods </p>
			  </p>
			</p>
		  </p>
		</div>
	  </Col>
	  <Col xs={12} md={8} lg={6}>
		<div className="m-3 mt-4">
		  <Image
			src="./Images/Lab-Site-Selection-2.jpg"
			style={{ width: "100%",height:"60vh" }} className='shado rounded-2'
		  />
		</div>
	  </Col>
	</Row>
  </Container>

	<section>
	<h1 className='text-center my-5 border container' style={{color:"#4aa439"}}>WHO WE ARE</h1>
	<div className='row container' >
	<div className='col text-center'>
	<Image src="./Images/who.webp"  ></Image>
	</div>
	<div className='col'>
	<p className=" my-5">
	The Indian Scientific Group was established in 2013 after its incorporation in 2004. The Indian Scientific Group's first subsidiary was first chosen for product certification
 for both domestic and Foreign manufacturers. There were just four certification Ranges available FMCS, CRS, FSSAI and BIS/ISI.Then, for the purpose to further expand our company, we had to
 form an LLP, but the Indian government refused to approve the formation of Indian Scientific LLP. Next, we incorporated the company as World Standards Scientific LLP, Lastly, in the year 2024, we established "World Standards Scientific LLP."
	</p>
	</div>
	</div>
	<br /> 
	</section>
	<h1 className="my-5 text-center container border" style={{color:"#4aa439"}}>OUR SERVICES</h1>
	<div className="container text-center border  ">
	<div className="row justify-content-center">
		<div className="col-sm-4 col-md-4 col-lg-4 mt-5">
			<Image src="./Images/Brand/Isi_mark logo.jpg"  alt="Responsive image" style={{width:"199px"}}/>
		</div>
		<div className="col-sm-4 col-md-4 col-lg-4 col-xl-3 mt-5 brand icon-zoom">
			<Image src="./Images/Brand/FMCS.webp" alt="Responsive image" style={{width:"250px"}}/>
		</div>

		<div className="col-sm-4 col-md-4 col-lg-4 col-xl-3 mt-5 brand icon-zoom">
			<Image src="./Images/Brand/CRS R.jpg"  alt="Responsive image"/>
		</div>

		<div className="col-sm-4 col-md-4 col-lg-4 col-xl-3 mt-5 brand icon-zoom">
			<Image src="./Images/Brand/FSSAI Logo.jpg"  alt="Responsive image"/>
		</div>
		
	</div>
	<p className="mt-5 text-center" style={{color:"#3128b2"}}> And Many more...</p>
	</div>

	<div className="container border my-5">

	<div class>
		<table className="table table-striped ">
			<thead>
				<tr className="text-center fs-5 table-success" >

					<th scope="col ">LICENSE AND CERTIFICATION</th>
				</tr>
			</thead>
			<tbody>
				<tr className="text-center fs-5">

					<td>Domestic Product Certification (ISI Mark)</td>
				</tr>
				<tr className="text-center fs-5">

					
					<td>BIS (ISI Mark) For Foreign Manufacturers</td>

				</tr>
				<tr className="text-center fs-5">

					<td> CRS For Electronics & IT Goods</td>

				</tr>
				<tr className="text-center fs-5">

					<td>Food License Certification</td>

				</tr>

				

			</tbody>
		</table>
	</div>

</div>


<br/>


<Container>
<h1 className='text-center my-5 border' style={{color:"#4aa439"}}>DESIGNATED PARTNER</h1>
<Row className='justify-content-center text-center'>
<Col xs={12} md={4} style={{paddingLeft:"80px"}} className='mt-2 '>
<Card style={{ width: '18rem' }}>
<Card.Img variant="top" src="./Images/Nikunj G..jpg" className='shado' />
<Card.Body>
  <Card.Title>Mr. Nikunj G. Savaliya</Card.Title>
  <Card className='text-secondary'>Designated Partner</Card>
  
</Card.Body>
</Card>
</Col>
<Col xs={12} md={4} style={{paddingLeft:"80px"}} className='mt-2 '>
<Card style={{ width: '18rem' }}>
<Card.Img variant="top" src="./Images/vikash.jpg" className='shado' />
<Card.Body>
  <Card.Title>Mr. Vikas S. Chavda</Card.Title>
  <Card className='text-secondary'>Designated Partner</Card>
  
</Card.Body>
</Card>
</Col>
</Row>
<br/>
<p>Our team of engineers excel in all technical fields such as
Civil, Mechanical, Electrical, Electronics and Chemical etc. Our team also ensures that the procedure
and processes implemented in the production are in accordance with the requirements of standards
formulated by the Bureau of Indian Standards (BIS). The discrepancies are addressed promptly, and
training is provided at all levels and processes to ensure compliance with Indian Standards (IS). The
comprehensive details regarding the technical standards & procedures are prepared to ensure the right
execution of government guidelines.</p>
</Container>
</div>


)
}

export default About
